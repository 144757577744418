.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;



h1.page-title {
    margin-left: 100px;
    margin-top: 100px;
}

.images-container {
    display: flex;
    gap: 15px;
    // flex-wrap: wrap;
    padding-bottom: 100px;
    height: 10px;
}

.image-box {
    position: relative;
    flex: 1 1 20%;
    height: 350px;
    overflow: hidden;
    border-radius: 10px;
    // max-width: calc(25% - 10px);
}

.portfolio-image {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    position: absolute;
    width: 90%;
    z-index: 3;
    // padding: 10px 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: linear-gradient(
        180deg,
        rgba(0,0,0, 0.2) 0,
        rgba(0,0,0,1)
    );
    bottom: -70px;
}

.title {
    margin-bottom: 0;
    margin-top: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
}

.description {
    font-size: 13px;
    margin-bottom: 7px;
    color: #fff;
    font-weight: 700;
    text-align: center;
}

.btn{
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 10px;
    height: 30px;
    line-height: 4px;
    border: 2px solid;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    background: transparent;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
}

.btn:hover {
    transform: translateY(-3px);
    background: #0e0d0c;
}

&:after {
    content: "";
    background: linear-gradient(180deg, #0e0d0c,#00000000); 
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
}

&:hover:after {
    opacity: 0.85;
}

&:hover .content {
    bottom: 0;
}
}

// @media screen and (max-width: 1200px) {
//     .container .portfolio-page {
//         width: 100%;
//         padding: 20px;
//         box-sizing: border-box;

//         h1.page-title {
//             margin-left: 20px;
//             margin-top: 20px;
//         }

//         .image-box {
//             height: 20px;
//             max-width: calc(50% - 10px);
//         }
//     }
// }

// @media screen and (max-width: 768px) {
//     .portfolio-page {
//       padding-left: 2px;
//       padding-right: 20px;
//       width: 400px;
//     }
  
//     .container .portfolio-page .image-box {
//       width: 100%;
//       padding: 0;
//       box-sizing: border-box;
//       height: 300px; /* Adjust the height as needed for better display on mobile */
//       max-width: 100%;
//     }
  
//     .content {
//       padding: 10px;
//       bottom: -50px; /* Adjust the value to position the content properly on mobile */
//     }
  
//     .title {
//       font-size: 20px; /* Adjust the font size for better readability on mobile */
//     }
  
//     .description {
//       font-size: 12px; /* Adjust the font size for better readability on mobile */
//     }
//   }

@media screen and (max-width: 768px) {
    .portfolio-page {
      padding-left: 2px;
      padding-right: 20px;
      width: 400px;
    }
  
    .container .portfolio-page .image-box {
      width: 100%;
      padding: 0;
      box-sizing: border-box;
      height: 300px; /* Adjust the height as needed for better display on mobile */
      max-width: 100%;
    }
  
    .content {
      padding: 10px;
      bottom: -50px; /* Adjust the value to position the content properly on mobile */
  
      /* Center the content vertically and horizontally */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .title {
      font-size: 20px; /* Adjust the font size for better readability on mobile */
      margin-bottom: 10px;
      margin-top: 0;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-align: center; /* Center the title text */
    }
  
    .description {
      font-size: 12px; /* Adjust the font size for better readability on mobile */
      font-size: 144px;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 700;
      text-align: center; /* Center the description text */
    }
  
    .btn {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 15px 30px; /* Adjust the padding for better button size */
      line-height: 1;
      border: 2px solid;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }
  
    /* Rest of your CSS... */
  }
  
  